import { createApp } from 'vue'
import App from './App.vue'
import { createRouter, createWebHistory } from 'vue-router'
import './assets/styles/main.css';

// Importa o define tus componentes que utilizarás en las rutas
import Home from './components/Home.vue'
import Pagar from './components/Pagar.vue'
import PagarPremium from './components/PagarPremium.vue'
import PaymentSuccess from './components/PaymentSuccess.vue'
import PaymentCancel from './components/PaymentCancel.vue'
import UsiteUvot from './components/UsiteUvot.vue'
import UvotPaymentSuccess from './components/UvotPaymentSuccess.vue'
import UvotPaymentCancel from './components/UvotPaymentCancel.vue'
import PagarPremiumdLocal from './components/PagarPremiumdLocal.vue'
import DlocalSuccess from './components/DlocalSuccess.vue'
import Pagarulocal from './components/PagaruLocal.vue'
import UsiteUvotdLocal from './components/UsiteUvotdLocal.vue'
import UvotdLocalSuccess from './components/UvotdLocalSuccess.vue'


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/pagar/:plan/:id/:tipo/:tarifa',
    name: 'pagar',
    component: Pagar,
    props: true
  },
  {
    path: '/PagarPremium/:id',
    name: 'PagarPremium',
    component: PagarPremium,
    props: true
  },
  {
    path: '/UsiteUvot/:empresa/:usuario/:c_usuarios/:paquete',
    name: 'UsiteUvot',
    component: UsiteUvot,
    props: true
  },

  {
    path: '/uvotpayment/success',
    name: 'uvotpaymentSuccess',
    component: UvotPaymentSuccess,
    props: route => ({
      paymentid: route.query.paymentid,
      token: route.query.token,
      payerId: route.query.PayerID
    })
  },

  {
    path: '/uvotpayment/cancel',
    name: 'uvotpaymentCancel',
    component: UvotPaymentCancel,
    props: route => ({
      paymentId: route.query.paymentId,
      token: route.query.token,
      payerId: route.query.PayerID
    })
  },

  {
    path: '/payment/success',
    name: 'paymentSuccess',
    component: PaymentSuccess,
    props: route => ({
      paymentId: route.query.paymentId,
      token: route.query.token,
      payerId: route.query.PayerID
    })
  },

  {
    path: '/payment/cancel',
    name: 'paymentCancel',
    component: PaymentCancel,
    props: route => ({
      paymentId: route.query.paymentId,
      token: route.query.token,
      payerId: route.query.PayerID
    })
  },

  {
    path: '/PagarPremiumdLocal/:id',
    name: 'PagarPremiumdLocal',
    component: PagarPremiumdLocal,
    props: true
  },

  {
    path: '/dlocal/success',
    name: 'dlocalSuccess',
    component: DlocalSuccess,
    props: route => ({
      paymentId: route.query.paymentId,
      token: route.query.token,
      payerId: route.query.PayerID
    })
  },

  {
    path: '/pagarulocal/:plan/:id/:tipo/:tarifa',
    name: 'pagarulocal',
    component: Pagarulocal,
    props: true
  },

  {
    path: '/UsiteUvotdLocal/:empresa/:usuario/:c_usuarios/:paquete',
    name: 'UsiteUvotdLocal',
    component: UsiteUvotdLocal,
    props: true
  },

  {
    path: '/uvotdlocal/success',
    name: 'uvotdlocalSuccess',
    component: UvotdLocalSuccess,
    props: route => ({
      paymentId: route.query.paymentId,
      token: route.query.token,
      payerId: route.query.PayerID
    })
  }

  
  

  // Otras rutas si las tienes...
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

createApp(App).use(router).mount('#app');
