<template>
  <div id="app">
    <div v-if="responseData" class="min-h-screen bg-gray-50 flex items-center justify-center">
      <div class="bg-white rounded-lg shadow-md p-8 mx-4">


        <img src="../assets/logo.webp" alt="Logo" class="w-28 " />
        <br>
        <div class="flex items-center space-x-4">

          <div class="space-y-2">
            <h2 class="text-3xl font-bold">{{ responseData.plan.nombre }}</h2>
            <p v-if="$route.params.tipo == 1" class="text-xl">
              <del>${{ responseData.tarifa.monto * 12 }} USD</del> -35%
            </p>
            <p class="text-2xl font-semibold">
              {{
                $route.params.tipo == 0
                ? responseData.tarifa.monto + ' USD'
                : $route.params.tipo == 1
                  ? ` ${(responseData.tarifa.monto * 12 * 0.65).toFixed(2)} USD `
                  : ''
              }}
            </p>

            <p class="text-sm font-normal">
              {{ responseData.plan.descripcion }}
            </p>
          </div>
        </div>

        <button @click="almacenarId"
          class="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded-md transition duration-300 ease-in-out mt-6 relative"
          :disabled="cargando">

          <div v-if="cargando" class=" items-center justify-center">
            <svg class="animate-spin h-5 w-5 mr-3 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
              viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.373A8 8 0 0112 4.001v4.002C8.732 8.003 5.997 9.9 6 13.373h0zm8 0A8 8 0 0112 20v-4.001c3.268 0 6.003-1.897 6-5.371h-2z">
              </path>
            </svg>

          </div>

          <div v-else>
            Pagar
          </div>

        </button>
      </div>
    </div>
    <div v-else class="min-h-screen  flex items-center justify-center">
      <svg class="animate-spin h-10 w-10 mr-3 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none"
        viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.373A8 8 0 0112 4.001v4.002C8.732 8.003 5.997 9.9 6 13.373h0zm8 0A8 8 0 0112 20v-4.001c3.268 0 6.003-1.897 6-5.371h-2z">
        </path>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TuCoDlocal',
  data() {
    return {
      responseData: null,
      loading: true,
      token: null,
      cargando:false,
    };
  },
  mounted() {
    const planValue = this.$route.params.plan;
    const tarifaValue = this.$route.params.tarifa;


    fetch(`https://api.u-site.app/consultar_plan_dlocal?plan=${planValue}&tarifa=${tarifaValue}`)
    //fetch(`http://localhost:8080/consultar_plan_dlocal?plan=${planValue}&tarifa=${tarifaValue}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Error al obtener los datos');
        }
        return response.json();
      })
      .then(data => {
        this.responseData = data;
      })
      .catch(error => {
        console.error('Error al obtener datos de la API:', error);
      })
      .finally(() => {
        this.loading = false;
      });

  },


  methods: {

    async almacenarId() {
      const tiempo = this.$route.params.tipo == 0 ? 1 : 12;
      const formData = new FormData();

      formData.append('plan_id', this.$route.params.plan);
      formData.append('tiempo', tiempo);
      formData.append('tarifa', this.responseData.tarifa.monto);
      formData.append('estado', 'pendiente');
      formData.append('user_id', this.$route.params.id);
      formData.append('moneda', 'USD');
      formData.append("success_url","https://vcard.u-site.app/planes")
      formData.append("back_url","https://vcard.u-site.app/planes")
      formData.append("notification_url","https://api.u-site.app/actualizar_estado_dlocal")

      console.log(formData);

      const requestOptions = {
        method: "POST",
        body: formData,
        redirect: "follow"
      };

      //fetch('http://localhost:8080/guardar_plan_transaccion_dlocal', requestOptions)
      fetch('https://api.u-site.app/guardar_plan_transaccion_dlocal', requestOptions)
  
        .then((response) => response.json())
        .then((data) => {
          if (data.redirect_url) {
            window.location.href = data.redirect_url;
          } else {
            console.error('No se proporcionó una URL de redirección en la respuesta.');
          }
        })
        .catch((error) => console.error(error));

    }

  }
}

</script>

<style>
/* Estilos específicos para TuComponente.vue si es necesario */
</style>
