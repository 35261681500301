<template>
  <div id="app" class="min-h-screen bg-gray-50 flex items-center justify-center">
    <div v-if="!loading">
      <div>
        <div class="flex ">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-green-500 inline-block" fill="none"
            viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
          </svg>
          <h1 class="text-green-600 font-semibold">Pago completado con éxito</h1>
        </div>
        <p>Detalles del pago:</p>
        <ul>
          <li><strong>Payment ID:</strong> {{ paymentId }}</li>
          <li><strong>Token:</strong> {{ token }}</li>
          <li><strong>Payer ID:</strong> {{ payerId }}</li>
        </ul>

        <a href="https://vcard.u-site.app/planes" class="text-green-500 font-semibold hover:underline"><button
            class="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded-md transition duration-300 ease-in-out mt-6 relative">
            <div>
              Volver al comercio
            </div>
          </button>
        </a>
      </div>
    </div>

    <div v-else class="min-h-screen  flex items-center justify-center">
      <svg class="animate-spin h-10 w-10 mr-3 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none"
        viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.373A8 8 0 0112 4.001v4.002C8.732 8.003 5.997 9.9 6 13.373h0zm8 0A8 8 0 0112 20v-4.001c3.268 0 6.003-1.897 6-5.371h-2z">
        </path>
      </svg>
    </div>

  </div>
</template>

<script>
export default {
  name: 'PaymentSuccess',
  data() {
    return {
      loading: false,
    };
  },
  props: {
    paymentId: String,
    token: String,
    payerId: String
  },
  mounted() {
    // Accediendo a los parámetros recibidos en las props
    console.log('paymentId:', this.paymentId);
    console.log('token:', this.token);
    console.log('payerId:', this.payerId);

    this.actualizaPago();
    // Realizar acciones adicionales si es necesario...
  },
  methods: {
    actualizaPago() {
      this.loading = true;
      fetch(`https://api.u-site.app/actualizar_estado?paymentid=${this.paymentId}&estado=approved`)
      //fetch(`http://localhost:8080/actualizar_estado?paymentid='PAYID-MXCPLZY5VS39849X10071426'&estado=approved`)
        .then(response => {
          if (!response.ok) {
            throw new Error('Error al obtener los datos');
          }
          return response.json();
        })
        .then(data => {
          console.log(data);
          this.loading=false;

        })
        .catch(error => {
          console.error('Error al obtener datos de la API:', error);
          this.loading=false;
          // Manejo de errores
        });
    }
    // Otros métodos, lógica, etc.
  }
}
</script>

<style>
/* Estilos específicos para el componente PaymentSuccess.vue si es necesario */
</style>
