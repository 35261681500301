<template>
    <div id="app" class="min-h-screen bg-gray-50 flex items-center justify-center">
      <div>
        <h1 class="font-semibold text-red-600">Pago Cancelado</h1>
        <p>Detalles del pago:</p>
        <ul>
          <li><strong>Token:</strong> {{ token }}</li>
        </ul>
        <a href="https://uvot.u-site.app/planes" class="text-green-500 font-semibold hover:underline"><button
              class="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded-md transition duration-300 ease-in-out mt-6 relative">
              <div>
                Volver al comercio
              </div>
            </button>
          </a>
        <!-- Aquí puedes agregar más contenido según sea necesario -->
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'UvotPaymentSuccess',
    props: {
      paymentId: String,
      token: String,
      payerId: String
    },
    mounted() {
      // Accediendo a los parámetros recibidos en las props
      console.log('paymentId:', this.paymentId);
      console.log('token:', this.token);
      console.log('payerId:', this.payerId);
  
      // Realizar acciones adicionales si es necesario...
    },
    // Otros métodos, lógica, etc.
  }
  </script>
  
  <style>
  /* Estilos específicos para el componente PaymentSuccess.vue si es necesario */
  </style>
  