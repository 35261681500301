<template>
  <div id="app">
    <!-- Barra de navegación u otros elementos comunes a todas las páginas -->
    <!----<nav>
      <router-link to="/">Home</router-link>
      <router-link :to="{ name: 'pagar', params: { plan: '63893fa4de386f080c0c06e7', id: '63ccb59e22ac43a52504f96d', tipo: '1' , tarifa: '638d6968010d61f28f0dbce4' }}">Pagar</router-link>
    </nav>-->

    <!-- El componente que cambia según la ruta -->
    <router-view v-bind="$attrs" />

  </div>
</template>

<script>
export default {
  name: 'App',
  // Componente principal de la aplicación
}
</script>

<style>
/* Estilos globales o específicos para App.vue */
</style>
