<template>
    <div id="app">
      <div v-if="responseData" class="min-h-screen bg-gray-50 flex items-center justify-center">
        <div class="bg-white rounded-lg shadow-md p-8 mx-4">
  
  
          <img src="../assets/logo.webp" alt="Logo" class="w-28 " />
          <br>
          <div class="flex items-center space-x-4">
  
            <div class="space-y-2">
              <h2 class="text-3xl font-bold">Paquete premium</h2>
              <p class="text-xl">
                ${{ responseData.premium_price }} USD
              </p>
              <p class="text-sm font-normal">
                {{ responseData.descripcion_prime }}
              </p>
            </div>
          </div>
  
          <button @click="obtenerTokenPago"
            class="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded-md transition duration-300 ease-in-out mt-6 relative"
            :disabled="cargando">
  
            <div v-if="cargando" class=" items-center justify-center">
              <svg class="animate-spin h-5 w-5 mr-3 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
                viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.373A8 8 0 0112 4.001v4.002C8.732 8.003 5.997 9.9 6 13.373h0zm8 0A8 8 0 0112 20v-4.001c3.268 0 6.003-1.897 6-5.371h-2z">
                </path>
              </svg>
  
            </div>
  
            <div v-else>
              Pagar
            </div>
  
          </button>
        </div>
      </div>
      <div v-else class="min-h-screen  flex items-center justify-center">
        <svg class="animate-spin h-10 w-10 mr-3 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none"
          viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.373A8 8 0 0112 4.001v4.002C8.732 8.003 5.997 9.9 6 13.373h0zm8 0A8 8 0 0112 20v-4.001c3.268 0 6.003-1.897 6-5.371h-2z">
          </path>
        </svg>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'TuCo',
    data() {
      return {
        responseData: null,
        loading: true,
        token: null,
        cargando:false,
      };
    },
    mounted() {
      const id = this.$route.params.id;
  
  
      fetch(`https://api.u-site.app/consultar_plan_premium`)
      //fetch(`http://localhost:8080/consultar_plan_premium`)
        .then(response => {
          if (!response.ok) {
            throw new Error('Error al obtener los datos');
          }
          return response.json();
        })
        .then(data => {
          this.responseData = data;
          console.log(id);
        })
        .catch(error => {
          console.error('Error al obtener datos de la API:', error);
        })
        .finally(() => {
          this.loading = false;
        });
  
    },
  
    methods: {
      obtenerTokenPago() {
        this.cargando = true;
        fetch(`https://api.u-site.app/token_plan`)
        //fetch(`http://localhost:8080/token_plan`)
          .then(response => {
            if (!response.ok) {
              throw new Error('Error al obtener los datos');
            }
            return response.json();
          })
          .then(data => {
            this.token = data.AccessToken.access_token;
            //console.log(this.token);
  
            // Una vez que se obtiene el token, podrías habilitar alguna bandera o marcar que el token está disponible.
            // Por ejemplo:
            this.tokenObtenido = true; // Asumiendo que tienes una propiedad tokenObtenido en tu data()
            this.procesarPago();
          })
          .catch(error => {
            console.error('Error al obtener datos de la API:', error);
            // Manejo de errores
          });
      },
  
      procesarPago() {
        //const paymentID= null;
  
        if (!this.tokenObtenido) {
          console.error('Token de pago no disponible.');
          return;
        }
        // Lógica de PayPal
        //const CREATE_URL = 'https://api.sandbox.paypal.com/v1/payments/payment';
        const CREATE_URL = 'https://api.paypal.com/v1/payments/payment';
        //const EXECUTE_URL = 'https://api.sandbox.paypal.com/v1/payments/payment/';
  
        // Lógica para crear el pago
        fetch(CREATE_URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.token}`,
          },
          body: JSON.stringify({
            intent: 'sale',
            payer: {
              payment_method: 'paypal'
            },
            transactions: [{
              amount: {
                total: this.responseData.premium_price,
                currency: 'USD'
              },
              description: 'Plan premium',
              custom: '1234569789',
            }],
            redirect_urls: {
              return_url: 'http://pay.u-site.app/payment/success',
              cancel_url: 'http://pay.u-site.app/payment/cancel'
              //return_url: 'http://localhost:8081/payment/success',
              //cancel_url: 'http://localhost:8081/payment/cancel'
            }
          })
        })
          .then(response => response.json())
          .then(data => {
            //const paymentID = data.id; // Obtener el ID del pago creado
            //console.log(data);
            this.paymentID = data.id;
            // Obtener la URL de aprobación (approval_url) de la respuesta
            this.approvalURL = data.links.find(link => link.rel === 'approval_url').href;
            this.almacenarId();
            // Redirigir al usuario a la URL de aprobación de PayPal para autorizar el pago
  
  
          })
          .catch(error => {
            console.error('Error al crear el pago:', error);
            window.alert('Hubo un error al iniciar el proceso de pago.');
          });
      },
  
      almacenarId() {
        const id = this.$route.params.id;
        const formData = new FormData();
  
        formData.append('plan_id', 'NA');
        formData.append('tiempo','NA');
        formData.append('tarifa', this.responseData.premium_price);
        formData.append('estado', 'pendiente');
        formData.append('paymentID', this.paymentID);
        formData.append('user_id', id);
  
        console.log(formData);
  
        fetch('https://api.u-site.app/guardar_transaccion', {
        //fetch('http://localhost:8080/guardar_transaccion', {
          method: 'POST',
          body: formData,
        })
          .then(response => {
            if (!response.ok) {
              throw new Error('Error al realizar la solicitud');
            }
            return response.json();
          })
          .then(data => {
            console.log(data);
            if (data.message === 'success') {
              // Realizar alguna acción si la respuesta indica éxito
              // Por ejemplo, redireccionar a otra página
              this.cargando = false; 
              window.location.href = this.approvalURL;
            }
            // Asumiendo que tienes una propiedad tokenObtenido en tu data()
          })
          .catch(error => {
            console.error('Error al realizar la solicitud:', error);
            // Manejo de errores
          });
      },
    },
  
  
  };
  </script>
  
  <style>
  /* Estilos específicos para TuComponente.vue si es necesario */
  </style>
  